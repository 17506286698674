<template>
  <div class="container">
    <Loading :loading="loading" />
    <template v-if="!loading">
      <div class="title">
        <img
          class="news-image pointer"
          style="width: 100%"
          :src="detail.face_img"
          alt=""
        />
      </div>
      <div class="title">{{ L(detail.name) }}</div>
      <div class="author">
        <span
          >{{ L("发布时间") }}：{{
            detail.create_at | timeTrans("yyyy-MM-dd")
          }}</span
        >
        <span>{{ L("作者") }}：{{ L("管理员") }}</span>
      </div>
      <p v-html="(content)"></p>
      <a-button class="sign-up" type="primary" :disabled="true" v-if="isOver">
        {{L("活动已结束")}}
      </a-button>
      <template v-else>
       <a-button class="sign-up" type="primary" :disabled="true" v-if="isRegistered && startLength > 0">
        {{L(`已报名，活动开始还有${startLength}天`)}}
      </a-button>

        <template v-if="isRegistered && startLength <= 0">
           <Uploader
                :uuid="detail.uuid"
                @refresh="init"
                :path="signImage"
              />
                 <a-button class="sign-up" type="primary" :disabled="true" v-if="isSigned">
        {{L("已签到")}}
      </a-button>
          
        </template>
   
      <a-button class="sign-up" @click="add" :loading="addLoading" type="primary" v-if="!isRegistered">
        {{L('我要报名')}}
      </a-button>
      </template>

    </template>
  </div>
</template>

<script>
import { fetchDetail } from "@/api/activity";
import { enrollActivity, isRegisteredActivity } from "@/api/me";
import { mapMutations } from 'vuex';
import moment from 'moment';
import Uploader from "./uploader";
export default {

  components: {
    Uploader,
  },

  data() {
    return {
      uuid: null,
      detail: {},
      loading: false,
      addLoading: false,

      isRegistered: false, // 是否已报名
      isSigned: false, // 是否已签到
      signImage: null
    };
  },

  computed: {
    content() {

      const that = this;

      return this.detail.content ? this.L(this.detail.content).replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, function (match, capture) {
          return `<img style='width: 100%' src='${that.L2(capture)}'`;
      }) : '';

    },

    // 判断活动是否已结束
    isOver() {
      if (!this.detail.end_at) {
        return false;
      }
      const overDate = moment(parseInt(this.detail.end_at) * 1000);
      const now = moment();
      return moment(overDate.format("YYYY-MM-DD")).isBefore(now.format('YYYY-MM-DD'));
    },
    // 判断活动开始还有几天
    startLength() {
            if (!this.detail.start_at) {
        return false;
      }

      const startDate = moment(parseInt(this.detail.start_at) * 1000);
      const now = moment();
      return moment(startDate.format("YYYY-MM-DD")).diff(moment(now.format("YYYY-MM-DD")), 'days');
 
    },
  },

  mounted() {
    const { query } = this.$route;
    const { uuid } = query || {};
    if (uuid) {
      this.uuid = uuid;
      this.init();
    }
  },
  methods: {

    init() {
      this.getDetail();
      this.getStatus();
    },

    getDetail() {
      this.loading = true;
      fetchDetail({
        uuid: this.uuid,
      })
        .then((res) => {
          this.detail = res;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getStatus() {
      if (window.localStorage.getItem('token')) {
            isRegisteredActivity({
              uuid: this.uuid,
            }).then(res => {
              console.log('res', res)
              if(res._status === 1 && parseInt(res.enroll_at)) {
                this.isRegistered = true; // 等于 0 说明未报名
              }
              if(res._status === 1 && parseInt(res.sign_at)) {
                this.isSigned = true; // 等于 0 说明未签到
                this.signImage = res.sign_img
              } 
            })
        }
    },

    ...mapMutations('login', ['setVisible']),

    add() {
      const token = window.localStorage.getItem('token')
      if (!token) {
        this.setVisible(true)
        return;
      }

      this.addLoading = true;
      enrollActivity({
        uuid: this.detail.uuid,
      })
        .then((res) => {
          if (res._status === 1) {
            this.$message.success(this.L(res.info));
            this.init();
          }
        })
        .finally(() => {
          this.addLoading = false;
        });
    },
  },
};
</script>



<style lang="less" scoped>
.title {
  font-size: 30px;
  line-height: 1em;
  font-weight: 600;
  color: #000000;
  opacity: 0.8;
  text-align: center;
  padding-top: 67px;
}
.author {
  font-size: 12px;
  font-weight: 300;
  color: #000000;
  opacity: 0.8;
  text-align: center;
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.author > span:first-child {
  padding-right: 24px;
}
p {
  font-weight: 400;
  line-height: 45px;
  color: #000000;
  opacity: 0.8;
}
p:last-child {
  padding-bottom: 100px;
}

.image {
  display: block;
  width: 100%;
}

.sign-up {
  width: 385px;
  height: 53px;


  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px auto 146px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}
</style>


<style lang="less" scoped>
@media screen and (max-width: 767px) {
  .sign-up {
    width: 80vw;
    margin: 20px auto;
    height: 40px;
  }
}
</style>