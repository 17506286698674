import request from '../request'

export function fetchList(params) {
    return request({
        url: '/Api/Activity/getList',
        params,
    })
}

export function fetchDetail(params) {
    return request({
        url: '/Api/Activity/getDetail',
        params
    })
}


export function signActivity(params) {
    return request({
        url: '/Api/Me/signActivity',
        params
    })
}