<template>
  <div>
    <input ref="upload" style="display: none" type="file" @change="onChange" />
        <a-button class="sign-up" type="primary"
                :loading="loading"
                @click="onClick"
             >
            {{ path ? L("重新上传签到图片") : L("上传签到图片") }}
      </a-button>
  </div>
</template>

<script>
import { uploadFile } from "@/api/upload";
import {signActivity} from '@/api/activity'


export default {
  props: {
    path: {
      type: String,
    },
    uuid: {
        type: String,
    }
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    onClick() {
      this.$refs.upload.click();
    },

    onChange(e) {
      const files = e.target.files;
      const data = new FormData();
      for (let i = 0; i < files.length; i++) {
        data.append("file", files[i]);
      }

      this.loading = true;

      uploadFile(data)
        .then((res) => {
          console.log("upload res", res);
          if (res._status === 1) {
            // this.$message.success(this.L("上传成功"));

                signActivity({
                    uuid: this.uuid,
                    img: res.file.path
                }).then(result => {
                      if (result._status === 1) {
                          this.$message.success(this.L(result.info));
                          this.$emit('refresh')
                      }
                })
          }
        })
        .finally(() => {
          this.loading = false;
          e.target.value = null;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.sign-up {
  width: 385px;
  height: 53px;


  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px auto 146px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}
</style>


<style lang="less" scoped>
@media screen and (max-width: 767px) {
  .sign-up {
    width: 80vw;
    margin: 40px auto;
    height: 40px;
  }
}
</style>